import { useState, useContext, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../../../../../contexts/AuthContext";
import "./MaqPagamentosCliente.css";
import CustomDateRangePicker from "../CustomDatePicker/CustomRangePicker";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import * as links from "../../../../../utils/links";
import PagamentosTable from "../Table/PagamentosTable";
export default function MaqPagamentosCliente() {

  const location = useLocation();
  const maquinaInfos = location.state;

  const { setDataUser, authInfo, setNotiMessage } = useContext(AuthContext);
  const { dataUser } = authInfo;

  let navigate = useNavigate();
  const token = authInfo?.dataUser?.token;
  const [estornos, setEstornos] = useState("");
  const [loadingTable, setLoadingTable] = useState(false);
  const [listCanals, setListCanals] = useState([]);
  const [estoque, setEstoque] = useState("");
  const [dataInicio, setDataInicio] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [cash, setCash] = useState("");
  const [total, setTotal] = useState("");

  const { id } = useParams();

  useEffect(() => {
    getData(id);
  }, []);

  useEffect(() => {
    if (dataFim != null) {
      getPaymentsPeriod(dataInicio, dataFim);
    }
  }, [dataFim]);


  const getPaymentsPeriod = (dataInicio, dataFim) => { 
    if (id.trim() !== "") {
      setLoadingTable(true);
      const url = `${process.env.REACT_APP_SERVIDOR}/pagamentos-periodo/${id}`;
  
      // Validar dataInicio
      const dataInicioISO = dataInicio 
        ? `${dataInicio}T03:00:00.000Z` 
        : null;
  
      // Validar dataFim e adicionar um dia
      let dataFimISO = null;
      if (dataFim) {
        const dataFimObj = new Date(dataFim);
        dataFimObj.setDate(dataFimObj.getDate() + 1); // Adicionar um dia
        dataFimISO = `${dataFimObj.toISOString().split("T")[0]}T03:00:00.000Z`;
      }
  
      const requestBody = {};
      if (dataInicioISO) requestBody.dataInicio = dataInicioISO;
      if (dataFimISO) requestBody.dataFim = dataFimISO;
  
      axios
        .post(
          url,
          requestBody,
          {
            headers: {
              "x-access-token": token,
              "content-type": "application/json",
            },
          }
        )
        .then((res) => {
          setLoadingTable(false);
          setEstornos(res.data.estornos);
          setCash(res?.data?.cash);
          setTotal(res.data.total);
          if (res.status === 200 && Array.isArray(res.data.pagamentos)) {
            setListCanals(res.data.pagamentos);
          }
        })
        .catch((err) => {
          setLoadingTable(false);
          if ([401, 403].includes(err.response.status)) {
            setNotiMessage({
              type: "error",
              message:
                "A sua sessão expirou, para continuar faça login novamente.",
            });
            if(dataUser.key === 'ADMIN'){
              navigate(links.ADMIN_SIGNIN);
              setDataUser(null);
            } else {
              navigate(links.SIGNIN);
              setDataUser(null);
            }
          }
        });
    }
  };

  const getData = (id) => {
    if (id.trim() !== "") {
      setLoadingTable(true);
      axios
        .get(`${process.env.REACT_APP_SERVIDOR}/pagamentos/${id}`, {
          headers: {
            "x-access-token": token,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data);
          setLoadingTable(false);
          setEstornos(res.data.estornos);
          setCash(res?.data?.cash);
          setEstoque(res?.data?.estoque);
          setTotal(res.data.total);
          if (res.status === 200 && Array.isArray(res.data.pagamentos)) {
            setListCanals(res.data.pagamentos);
          }
        })
        .catch((err) => {
          setLoadingTable(false);
          if ([401, 403].includes(err.response.status)) {
            setNotiMessage({
              type: "error",
              message:
                "A sua sessão expirou, para continuar faça login novamente.",
            });
            if(dataUser.key === 'ADMIN'){
              navigate(links.ADMIN_SIGNIN)
              setDataUser(null);
            }else{
              navigate(links.SIGNIN)
              setDataUser(null);
            }
          }
        });
    }
  };

  const onRelatorioHandler = () => {
    if (!dataInicio && !dataFim) {
      setNotiMessage({
        type: "error",
        message:
          "Selecione no calendário a data de início e fim para gerar o relatório para essa maquina!",
      });
    } else {
      navigate(`${links.RELATORIO}/${id}`, {
        state: { maquinaInfos, dataInicio, dataFim },
      });
    }
  };

  const onRelatorioDispHandler = () => {
    navigate(`${links.RELATORIO_DISP}/${id}`, {
      state: { maquinaInfos, dataInicio, dataFim },
    });
  };

  return (
    <div class="container mw-100">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1 className="h4 fw-bold">{maquinaInfos?.nome}</h1>
        <div className="d-flex col-6 col-md-auto mb-2">
          <button className="custom-btn btn btn-outline-secondary"
          onClick={() =>
            navigate(`${links.DASHBOARD_FORNECEDOR}`)
          }>VOLTAR</button>
        </div>
      </div>
      <div className="d-flex justify-content-start align-items-center mb-4">
        <div className="d-flex flex-wrap">
          <CustomDateRangePicker setDataFim={setDataFim} setDataInicio={setDataInicio} resetSearch={getData} id={id}/>
          <div className="d-flex col-7 col-md-auto">
            <button className="custom-btn btn btn-outline-primary d-flex w-100 mb-2"
            onClick={() => {
                navigate(`${links.EDIT_FORNECEDOR_CANAIS}/${id}`, {
                  state: location.state,
                });
              }}>
              <i className="bi bi-pencil" style={{marginRight: '5px'}}></i>
              Editar Máquina
            </button>
          </div>
          <div className="d-flex col-7 col-md-auto">
            <button className="custom-btn btn btn-outline-primary d-flex w-100 mb-2" onClick={() => {
              navigate(links.REMOTE_CREDIT.replace(":id", id), {
                state: location.state,
              });
            }}>
              <i className="bi bi-credit-card" style={{marginRight: '5px'}}></i> Crédito Remoto
            </button>
          </div>
          <div className="d-flex col-7 col-md-auto">
            <button
              className="custom-btn btn btn-outline-primary d-flex w-100 mb-2"
              onClick={() => {
                const url = `//www.mercadopago.com.br/stores/detail?store_id=${maquinaInfos.store_id}`;
                window.open(url, '_blank');
              }}
            >
              <i className="bi bi-qr-code" style={{ marginRight: '10px' }}></i>
              QR Code
            </button>
          </div>
          <div className="d-flex col-7 col-md-auto">
            <button className="custom-btn btn btn-outline-primary d-flex w-100 mb-2" onClick={() => onRelatorioHandler()}>
                <i className="bi bi-file-earmark-text" style={{ marginRight: '10px' }}></i>
                Rel. de Pagamentos
            </button>
          </div>
          <div className="d-flex col-7 col-md-auto">
            <button className="custom-btn btn btn-outline-primary d-flex w-100 mb-2" onClick={() => onRelatorioDispHandler()}>
                <i className="bi bi-file-bar-graph" style={{ marginRight: '10px' }}></i>
                Rel. de Disponibilidade
            </button>
          </div>
          {/* <div className="d-flex col-7 col-md-auto">
            <button className="custom-btn btn btn-outline-primary d-flex w-100 mb-2"
            onClick={() => {
              navigate(`${links.CLIENTES_MAQUINAS_TROCAR}/${id}`, {
                state: location.state,
              });
            }}>
            <i className="bi bi-arrow-counterclockwise" style={{marginRight: '10px'}}></i>
              Trocar ID
            </button>
          </div> */}
          <div className="d-flex col-7 col-md-auto">
            <button className="custom-btn btn btn-outline-danger d-flex w-100 mb-2"
             onClick={() => {
                navigate(`${links.DELETE_FORNECEDOR_CANAIS}/${id}`, {
                  state: location.state,
                });
              }}>
              <i className="bi bi-trash" style={{marginRight: '5px'}}></i> 
              Excluir Pagamentos
            </button>
          </div>
        </div>
      </div>


      {/* Filtros */}
      {/* <div className="d-flex justify-content-start align-items-center mb-4">
        <div className="d-flex flex-wrap gap-2">
          <CustomDateRangePicker/>
          <div className="d-flex col-6 col-md-auto">
            <input type="date" className="form-control w-100 mb-2" />
          </div>
          <div className="d-flex col-6 col-md-auto">
            <button className="custom-btn btn btn-outline-primary w-100 mb-2">Rel. de Pagamentos</button>
          </div>
          <div className="d-flex col-6 col-md-auto">
            <button className="custom-btn btn btn-outline-primary w-100 mb-2">Rel. de Disponibilidade</button>
          </div>
        </div>
      </div> */}

      {/* Cards */}
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 mb-4">
        <div className="col-7 col-lg-3 mb-2">
          <div className="card custom-card">
            <div className="card-body">
              <h6 className="text-muted">Total</h6>
              <h5 className="fw-bold">{Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(total)}</h5>
            </div>
          </div>
        </div>
        <div className="col-7 col-lg-3 mb-2">
          <div className="card custom-card">
            <div className="card-body">
              <h6 className="text-muted">Estornos</h6>
              <h5 className="fw-bold">{Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })?.format(estornos)}</h5>
            </div>
          </div>
        </div>
        <div className="col-7 col-lg-3 mb-2">
          <div className="card custom-card">
            <div className="card-body">
              <h6 className="text-muted">Espécie</h6>
              <h5 className="fw-bold">{Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(cash)}</h5>
            </div>
          </div>
        </div>
        <div className="col-7 col-lg-3 mb-2">
            <div className="card custom-card">
                <div className="card-body">
                    <h6 className="text-muted">Pulso</h6>
                    <h5 className="fw-bold">{Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(maquinaInfos?.pulso)}</h5>
                </div>
            </div>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 mb-4">
        <div className="col-7 col-lg-3 mb-2">
          <div className="card custom-card">
            <div className="card-body">
              <h6 className="text-muted">Store ID</h6>
              <h5 className="fw-bold">{maquinaInfos?.store_id ?? '-'}</h5>
            </div>
          </div>
        </div>
        <div className="col-7 col-lg-3 mb-2">
          <div className="card custom-card">
            <div className="card-body">
              <h6 className="text-muted">Maquininha</h6>
              <h5 className="fw-bold">{maquinaInfos?.maquininha_serial ? maquinaInfos?.maquininha_serial : '-'}</h5>
            </div>
          </div>
        </div>
        <div className="col-7 col-lg-3 mb-2">
          <div className="card custom-card">
            <div className="card-body">
              <h6 className="text-muted">Estoque</h6>
              <h5 className="fw-bold">{estoque}</h5>
            </div>
          </div>
        </div>
      </div>

      {/* Tabela */}
      <div className="card" style={{border: '1px solid rgba(0, 0, 0, 0.1)', borderRadius: '8px'}}>
        <div className="card-header">
          <h5>{`${maquinaInfos?.nome} - ${maquinaInfos?.descricao}`}</h5>
        </div>
        <div className="card-body">
          <PagamentosTable listCanals={listCanals}/>
        </div>
      </div>
    </div>
  );
}