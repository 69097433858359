import React from 'react';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import "./PagamentosTable.css";

const PagamentosTable = ({ listCanals }) => {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th className="text-muted">Data</th>
          <th className="text-muted">Forma de pagamento</th>
          <th className="text-muted">Valor</th>
          <th className="text-muted">Identificador</th>
          <th className="text-muted">Status</th>
        </tr>
      </thead>
      <tbody>
        {listCanals.map((canal, index) => (
          <tr key={index}>
            <td>{moment(canal?.data)?.format("DD/MM/YYYY HH:mm:ss")}</td>
            <td>{canal?.tipo === "bank_transfer"
            ? "PIX"
            : canal?.tipo === "CASH"
            ? "Especie"
            : canal?.tipo === "debit_card"
            ? "Débito"
            : canal?.tipo === "credit_card"
            ? "Crédito"
            : canal?.tipo === "1"
            ? "Crédito"
            : canal?.tipo === "11"
            ? "PIX"
            : canal?.tipo === "8"
            ? "Débito"
            : "-"}</td>
            <td>{new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
            }).format(canal?.valor)}
            </td>
            <td>{canal?.mercadoPagoId}</td>
            <td>
                <OverlayTrigger
                    key={canal.key}
                    placement="bottom"
                    popperConfig={{ modifiers: [{ name: "preventOverflow", enabled: false }] }}
                    overlay={
                    <Tooltip id={`tooltip-top-${canal.key}`} style={{ zIndex: 1050 }}>
                        {canal?.motivoEstorno
                        ? canal?.motivoEstorno
                        : "Sem motivo registrado"}
                    </Tooltip>
                    }
                >
                    <span
                        className={`badge ${
                            canal?.estornado
                            ? "bg-light"
                            : "bg-success"
                        }`}
                    >
                        {canal?.estornado ? "Estornado" : "Recebido"}
                    </span>
                </OverlayTrigger>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PagamentosTable;
